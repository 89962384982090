<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>CASE JSON</h3>

	<p>The <a href="https://www.imsglobal.org/sites/default/files/CASE/casev1p0/information_model/caseservicev1p0_infomodelv1p0.html" target="_blank">CASE 1.0 Information Model</a> defines how a framework of competencies (e.g. learning standards) should be coded in JSON format according to the CASE specification. A typical framework defining a set of learning standards will have three essential structures:</p>
	<ol>
		<li>First, a single <span v-html="link('case_document', 'document')"></span> object serves as the “head” of the framework JSON structure and specifies metadata for the framework.</li>
		<li>Second, a set (array) of objects representing the <span v-html="link('case_items', 'items')"></span> (competencies, e.g. learning standards).</li>
		<li>Third, a set (array) of objects representing <span v-html="link('case_associations', 'associations')"></span> between items.</li>
	</ol>
	<p>See the linked help docs above for details on the data stored in each structure.</p>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	